import {createRef, useEffect, useState} from "react";
import FadeIn from "../Components/FadeIn";
import ReCAPTCHA from "react-google-recaptcha";
import {Form, Button, Col, Row} from "react-bootstrap";
import {sendContactEmail} from '../apis/contact';
import LoadingSpinner from "../Components/LoadingSpinner";

const Contact = (props) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [requestType, setRequestType] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const recaptchaRef = createRef();

    const requestTypes = [
        'Website',
        'API',
        'Database',
        'Consultation',
        'Something else'
    ]

    useEffect(()=>{
        window.title = 'Contact'
    },[])

    return (
        <FadeIn>
            <div className={"px-0 px-sm-5"}>
                <h1>Let&apos;s chat</h1>
                {showSpinner?<LoadingSpinner/>:''}
                {
                    !submissionStatus?
                        <Form onSubmit={
                            async (e) => {
                                e.preventDefault();

                                const token = await recaptchaRef.current.executeAsync()

                                setShowSpinner(true);

                                sendContactEmail(email, name, requestType, message, token)
                                    .then((res)=>{
                                        setSubmissionStatus(true);
                                    })
                                    .catch((error)=>{
                                        console.log(error)
                                    })
                                    .finally((thing)=>{
                                        console.log(thing)
                                        window.scrollTo(0, 0)
                                        setShowSpinner(false)
                                    })



                            }
                        }>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" required value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Name" required value={name} onChange={(e)=>{setName(e.target.value)}}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="request-type">
                                        <Form.Label>How can I help?</Form.Label><br/>
                                        {
                                            requestTypes.map((e)=>{
                                                return (
                                                    <Form.Check
                                                        key={e}
                                                        inline
                                                        label={e}
                                                        name="request-type"
                                                        type="radio"
                                                        checked={requestType===e}
                                                        onChange={()=>{setRequestType(e)}}
                                                        id={e.toLowerCase().replace(/\s/gm,'-')}
                                                    />
                                                )
                                            })
                                        }

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="message">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" placeholder="Message" rows={10} required value={message} onChange={(e)=>{setMessage(e.target.value)}}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" type="submit">
                                        Send Message
                                    </Button>
                                </Col>
                            </Row>

                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            />
                        </Form> :
                        <div>Thanks! We&apos;ll be in touch soon!</div>
                }


            </div>
        </FadeIn>
    )
}

export default Contact;