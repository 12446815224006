import FadeIn from "../Components/FadeIn";
import {Link} from 'react-router-dom'
import {useEffect} from "react";
const About = () => {

    useEffect(()=>{
        window.title = 'About'
    },[])

    return (
        <FadeIn>
            <div className={`px-0 px-sm-5`}>
                <h1>Hi, I&apos;m Dominic.</h1>

                <div className={"my-3"}>
                    Whether it was playing my GameBoy Colour in the back of the car on family trips, spending
                    lunch time in the Computer Lab at school learning how to make my very first website, or
                    always needing to have the newest phone as soon as it was released, I&apos;ve always been
                    interested in technology. In 2015, I wanted to take this a step further, and formalise my
                    education, so I commenced a Bachelor of Information and Technology (Majoring in Computer
                    Science) at the Queensland University of Technology. During this time, I realised that my
                    passion was grounded in all things web, whether that be something as simple as a website,
                    or as complex or a fullstack solution, running Dockerised containers in cloud-based solutions
                    and implemented using automated CI/CD pipelines.
                </div>

                <div className={"my-3"}>
                    I am currently based in Brisbane, Australia, and work as a Senior Software Engineer for
                    the Australian Telecommunication and Technology company, Telstra. Telstra has been a huge
                    part of my professional life, having started working with them back in 2013. My day to day
                    work has me working with Oracle and SQL Server databases, rapid prototyping and development
                    in Oracle&apos;s ApEx platform, coordinating and collaborating with both our national and international
                    partners, liaising with key stakeholders and more.
                </div>

                <div className={"my-3"}>
                    Like most of us, my world was turned upside down during the COVID-19 pandemic. All of a
                    sudden, I wasn&apos;t able to do all of the things I would normally do, like go to the office
                    or enjoy seeing friends. The silver lining of this period of my life was that all of a
                    sudden, I had a lot more time on my hands. During this time, I joined the TitanReach team
                    as their lead Fullstack Developer. I was responsible for not only designing and building
                    the main website for the game, but also creating the underlying account system used in-game.
                    During this time, I became familiar with Azure and SQLServer, as well as other third-party
                    systems and APIs like Discord or PayPal. Through this project, I was able to build scalable
                    infrastructure which was able to support high volumes of users, without impacting the
                    experience of others.
                </div>

                <div className={"my-3"}>
                    When I&apos;m not working, you can normally find me experimenting with some kind of new technology
                    (I&apos;m currently playing around with Next.js and Google Firebase), spending time with my two cats /
                    co-designers Astrid and Ophelia.
                </div>

                <div className={"my-3"}>
                    If you&apos;d like to learn a little bit more about some of the work I&apos;ve done in the past, check out
                    my <Link to={"/portfolio"}>Portfolio of Work</Link>, or feel free to <Link to={"/contact"}>
                    send me a message</Link>.
                </div>

            </div>
        </FadeIn>

    )
}

export default About;