import {useEffect, useState} from "react";
import {getPortfolio} from "../apis/portfolio";
import {Row, Col} from 'react-bootstrap';
import FadeIn from "../Components/FadeIn";
import _ from 'lodash';
import {useParams, useNavigate} from 'react-router-dom'
import '../styles/portfolio.css';

const Portfolio = () => {

    const { item } = useParams();
    const navigate = useNavigate();

    const [portfolioItems, setPortfolioItems] = useState([]);
    const [activePortfolioItem, setActivePortfolioItem] = useState('')

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant' // for smoothly scrolling
        });
        if(activePortfolioItem === '') {
            navigate(`/portfolio`)
        } else if (item !== activePortfolioItem) {
            navigate(`./${activePortfolioItem}`)
        }

    },[activePortfolioItem, item, navigate])

    const portfolioItemSmall = (name, client, thumbnail) => {
        return (
            <FadeIn>
                <div className={'portfolio-item-small'}>
                    <div className={`thumbnail d-flex flex-column-reverse align-items-baseline px-4 pb-2`} style={{"backgroundImage":`url('${thumbnail}')`}} onClick={()=>{setActivePortfolioItem(name)}}>
                        <span className={'client'}>{client}</span>
                        <span className={'title'}>{name}</span>
                    </div>
                </div>
            </FadeIn>
        )
    }

    const renderPortfolioItems = () => {
        return (
            <FadeIn>
                <Row>
                    {
                        portfolioItems.map(({client, name, thumbnail})=>{
                            return (
                                <Col xs={12} sm={3} className={"my-2"} key={name}>
                                    {portfolioItemSmall(name, client, thumbnail)}
                                </Col>
                            )
                        })

                    }
                </Row>
            </FadeIn>
        )
    }

    const portfolioItem = () => {
        return portfolioItems.filter(({name})=>{
            return name === activePortfolioItem
        }).map(({client, description, image, name})=>{
            return (
                <FadeIn key={name}>
                    <div className={`portfolio-item-focus mb-4`}>
                        <Row>
                            <Col xs={12} sm={6} className={"align-self-center gx-5 d-none d-sm-flex"}>
                                <img src={image} className={"float-end"} alt={"A sample of the portfolio item"}/>

                            </Col>
                            <Col xs={12} sm={6} className={"gx-5"}>
                                <h3 className={"fw-bold"}>{name}</h3>
                                <h5 className={"fw-bold"}>{client}</h5>
                                <div dangerouslySetInnerHTML={{__html: description}}></div>
                                <br/>
                                <span onClick={()=>{setActivePortfolioItem('')}} className={`back fw-bold`}>{'< Back'}</span>
                            </Col>
                        </Row>
                    </div>
                </FadeIn>
            )
        })
    }

    useEffect(()=>{
        getPortfolio().then((res)=>{
            setPortfolioItems(
                _.orderBy(
                    _.values(res),
                    ['order'],
                    ['asc']
                )
            )
        })
    },[])

    useEffect(()=>{
        if(item) {

            const foundItem = portfolioItems.filter(({name})=>{
                return name === item
            })[0]

            setActivePortfolioItem(foundItem?.name)
        }
    },[portfolioItems, item])

    useEffect(()=>{
        window.title = 'Portfolio'
    },[])


    return (
        <FadeIn>
            <div className={`px-0 px-sm-5`}>
                <h1>Portfolio</h1>

                {
                    portfolioItems.length>0?
                        activePortfolioItem?
                            portfolioItem()
                            :
                            renderPortfolioItems()
                        :
                        <span>Loading...</span>
                }


            </div>
        </FadeIn>
    )
}

export default Portfolio;