import {Link} from 'react-router-dom';
import '../styles/Logo.css'

const Logo = () => {
    return (
        <Link to={"/"}>
            <div className={'Logo'}>
                DF
            </div>
        </Link>
    )
}

export default Logo;