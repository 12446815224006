import {useEffect, useState} from "react";
import { getAuth } from "firebase/auth";
import {app} from '../config/firebase';
import {Link, useLocation} from 'react-router-dom'
import Hamburger from 'hamburger-react'
import Logo from './Logo';
import '../styles/Navbar.css';

const Navbar = () => {

    const location = useLocation();
    useEffect(() => {
        setShowNavbar(false)

    }, [location.key]);

    const auth = getAuth(app);

    const links = [
        {
            name: 'Home',
            url: '/'
        },
        {
            name: 'About',
            url: '/about'
        },
        {
            name: 'Portfolio',
            url: '/portfolio'
        },
        {
            name: 'Contact',
            url: '/contact'
        },
        auth.currentUser?
            {
                "name": "Logout",
                "url": "/logout"
            }:
            {
                "name": "Login",
                "url": "/login"
            }
    ]

    const [showNavbar, setShowNavbar] = useState(false);

    return (
        <div className={`Navbar ps-2 ps-sm-0 `}>
            <section className={`hero  ${showNavbar?'active':''}`}>
                <div className={'hero-inner'}>
                    <div className={`hero-divider`}>
                        <div className={`links text-center text-light`}>
                            {
                                links.map(({name, url})=>{
                                    return window.location?.pathname===url?<span className={'active-page-link'} onClick={()=>{setShowNavbar(false)}} key={url}>{name}</span>:<Link to={url} key={url}>{name}</Link>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Logo/>

            <div className={'menu-button'}>
                <Hamburger toggled={showNavbar} toggle={setShowNavbar} size={40}/>
            </div>

        </div>
    )
}

export default Navbar;