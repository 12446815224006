const axios = require('axios');

export const sendContactEmail = async (email, name, requestType, message, token) => {

    const config = {
        method: 'post',
        url: 'https://us-central1-personalportfolio-ff45e.cloudfunctions.net/Email/contact',
        headers: {
            'Content-Type': 'application/json'
        },
        data : {
            'email': email,
            'name': name,
            'request-type': requestType,
            'message': message,
            'token': token
        }
    };

    return axios(config);

}

