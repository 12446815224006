import React from 'react';
import styles from '../styles/FadeIn.css';

const FadeIn = (props) => {
    const {duration, children, element} = props;

    return (
        <div className={styles.FadeIn} style={{"animationDuration":`${duration || 1}s`}}>
            {element || children}
        </div>
    )
}

export default FadeIn;