import {useEffect, useState} from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {app} from '../config/firebase';
import {Form, Button} from 'react-bootstrap';
import {Navigate} from "react-router-dom";

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isTryingToLogin, setIsTryingToLogin] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false)

    const auth = getAuth(app);

    useEffect(()=>{
        if(auth.currentUser) {
            auth.signOut();
        }
    },[auth])

    const doLogin = async (e) => {
        e.preventDefault();

        setIsTryingToLogin(true)

        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setLoggedIn(true)
            })
            .catch((error) => {
                switch(error.message){
                    case 'Firebase: Error (auth/internal-error).':
                        setLoginError('Invalid username or password')
                        break;
                    case 'Firebase: Error (auth/user-not-found).':
                        setLoginError('Invalid username or password')
                        break;
                    case 'Firebase: Error (auth/wrong-password).':
                        setLoginError('Invalid username or password')
                        break;
                    case 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
                        setLoginError('Account locked. Try again later')
                        break;
                    default:
                        setLoginError(error.message)
                }

            })
            .finally(()=>{
                setIsTryingToLogin(false)
            });
    }

    return (
        loggedIn?<Navigate to={"/"}/>:<div className={`px-0 px-sm-5`}>
            <h1>Login</h1>
            <Form onSubmit={doLogin} validated={loginError.length>0}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" isInvalid={loginError} value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" isInvalid={loginError} value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                    <Form.Control.Feedback type="invalid">
                        {loginError}
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isTryingToLogin}>
                    Login
                </Button>
            </Form>
        </div>
    )
}

export default Login;