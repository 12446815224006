import {useEffect, useState} from "react";
import Navbar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/globals.css'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Home from './Home'
import About from './About'
import Contact from './Contact'
import Portfolio from './Portfolio'
import FourOhFour from "./404";
import Logout from "./Logout";
import Login from "./Login";

const App = (props) => {
    const [resizeTimer, setResizeTimer] = useState();

    useEffect(()=>{
        window.addEventListener("resize", () => {
            document.body.classList.add("stop-transitions");
            clearTimeout(resizeTimer);
            setResizeTimer(setTimeout(() => {
                document.body.classList.remove("stop-transitions");
            }, 400));
        });
    },[resizeTimer])

    return (
        <main className={"container-fluid pt-2 pt-sm-0"}>
            <BrowserRouter>
                <Navbar/>
                <Routes>
                    <Route exact path="/" element={<Home />}/>
                    <Route exact path="/about" element={<About />}/>
                    <Route exact path="/contact" element={<Contact />}/>
                    <Route exact path="/portfolio" element={<Portfolio />}/>
                    <Route exact path="/portfolio/:item" element={<Portfolio />}/>
                    <Route exact path="/logout" element={<Logout />}/>
                    <Route exact path="/login" element={<Login />}/>
                    <Route path="/" element={<FourOhFour />}/>
                </Routes>
            </BrowserRouter>

        </main>
    )
}

export default App
