import VerticalSkillCarousel from "../Components/VerticalSkillCarousel";
import {Link} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap';
import '../styles/Home.css'
import FadeIn from "../Components/FadeIn";
import {useEffect} from "react";

const Home = () => {
    const buttons = [
        {
            "name": "About",
            "url": "/about"
        },
        {
            "name": "Portfolio",
            "url": "/portfolio"
        },
        {
            "name": "Get in Touch",
            "url": "/contact"
        }
    ]

    useEffect(()=>{
        window.title = 'Dominic Fichera'
    },[])

    return (
        <FadeIn>
            <div className={`Home d-flex align-items-center`}>
                <Row className={`flex-fill`}>
                    <Col xs={12} sm={2} className={"d-flex justify-content-center align-items-center"}>
                        <img src={"/profilepicture.jpg"} className={"rounded-circle d-none d-sm-block"} alt={"Dominic Fichera"}/>
                    </Col>
                    <Col xs={12} sm={7} className={"text-center text-sm-start"}>
                        <span className={'name'}>Dominic Fichera</span>
                        <img src={"/profilepicture.jpg"} className={"rounded-circle d-block d-sm-none my-3"} style={{"margin":"0 auto 0 auto"}} alt={"Dominic Fichera"}/>
                        <VerticalSkillCarousel skills={['Software Engineer','Web Design','APIs','NodeJS','Oracle SQL','ReactJS','Oracle ApEx','SQL Server']}/>
                        <Row className={"mt-0 mt-sm-4 mb-4"}>
                            {
                                buttons.map(({name, url})=>{
                                    return (
                                        <Col xs={12} sm={4} className={"pt-4 pt-sm-0"} key={url}>
                                            <Link to={url}>
                                                <div className={`button d-flex align-items-center justify-content-center px-4 text-center`}><span className={"w-100"}>{name}</span><i className="bi bi-chevron-right ms-3"></i></div>
                                            </Link>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>

            </div>
        </FadeIn>

    )
}

export default Home;