import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css';

import '../styles/VerticalSkillCarousel.css';

// import Swiper core and required modules
import SwiperCore, {
    Autoplay,Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);


export default function VerticalSkillCarousel(props) {
    const {skills} = props;
    return (

            <Swiper
                centeredSlides={true}
                autoplay={{"delay": 5000,"disableOnInteraction": false}}
                navigation={false}
                direction={'vertical'}
                className={`VerticalSkillCarousel text-center text-sm-start`}
            >
                {
                    skills.map((skill)=>{
                        return <SwiperSlide key={skill}>{skill}</SwiperSlide>
                    })
                }
            </Swiper>

    )
}