import {Spinner} from "react-bootstrap";
import styles from '../styles/LoadingSpinner.css'

const LoadingSpinner = (props) => {
    const {fullScreen} = props;
    return (
        <div className={`FullPageSpinner ${fullScreen?styles.FullPageSpinner:styles.PartialPageSpinner}`}>
            <Spinner animation="border" role="status" className={styles["spinner-lg"]}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>

    )
}

export default LoadingSpinner;