import {useEffect} from "react";
import { getAuth } from "firebase/auth";
import {app} from '../config/firebase';
import {Navigate} from "react-router-dom";

const Logout = () => {

    const auth = getAuth(app);

    useEffect(()=>{
        if(auth.currentUser) {
            auth.signOut();
        }
    },[auth])


    return (
        <Navigate to={"/"}/>
    )
}

export default Logout;