import {Link} from "react-router-dom";
import {Row, Col} from 'react-bootstrap';
import {useEffect} from "react";

const FourOhFour = () => {

    useEffect(()=>{
        window.title = 'Page Not Found'
    },[])

    return (
        <div className={`d-flex align-items-center`}>
            <Row className={`flex-fill`}>
                <Col className={"text-center"}>
                    <span>We looked everywhere, but we couldn&apos;t find what you were looking for :(</span>
                    <br/>
                    <br/>
                    <Link to={"/"}>Go Home</Link>
                </Col>
            </Row>
        </div>
    )
}

export default FourOhFour;